<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3">
            <h3>Visit Tracker</h3>
          </div>

          <div class="widget-content widget-content-area">
            <div class="mb-3">
              <form @submit.prevent="filterTracker" @reset="resetTracker">
                <b-input-group>
                  <b-input-group-prepend class="w-25">
                    <b-form-select
                      v-model="filterOptionSelected"
                      :options="filterOptions"
                    ></b-form-select>
                  </b-input-group-prepend>

                  <flat-pickr
                    v-model="filterText"
                    :config="{
                      wrap: true,
                      altFormat: 'F j, Y',
                      altInput: true,
                      mode: 'range',
                    }"
                    class="form-control"
                    :placeholder="
                      'Select dates by ' +
                      filterOptionSelected.replaceAll('_', ' ')
                    "
                    v-if="
                      filterOptionSelected == 'appointment_date' ||
                      filterOptionSelected == 'registration_date' ||
                      filterOptionSelected == 'next_expected_date' ||
                      filterOptionSelected == 'missed_visits'
                    "
                  >
                  </flat-pickr>

                  <b-form-input
                    v-model.trim="filterText"
                    :placeholder="
                      'Search by ' + filterOptionSelected.replaceAll('_', ' ')
                    "
                    v-else
                  ></b-form-input>

                  <b-input-group-append>
                    <b-btn type="submit" variant="primary">Search</b-btn>
                    <b-btn
                      type="reset"
                      variant="outline-dark"
                      v-if="filterText"
                    >
                      Reset
                    </b-btn>
                  </b-input-group-append>
                </b-input-group>
              </form>
            </div>

            <div class="d-flex align-items-start">
              <b-btn
                variant="info"
                class="mr-2"
                size="sm"
                @click="exportVisits"
                v-if="patients.length"
              >
                <b-spinner label="Loading" v-if="exporting" small></b-spinner>
                Export to Mail
              </b-btn>

              <b-dropdown
                id="column-visibility"
                html="Column Visibility <i class='fa fa-caret-down'></i>"
                class="mb-2"
                variant="primary"
                size="sm"
                dropright
              >
                <b-dropdown-item
                  v-for="column in patientFields"
                  :key="column.key"
                  :class="
                    column.show === true ? 'border-left border-primary' : ''
                  "
                  href="javascript:void(0);"
                  @click.native.capture.stop="column.show = !column.show"
                >
                  {{ column.label }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <b-table
              :items="patients"
              :fields="patientFields.filter((field) => field.show === true)"
              :busy="patientsTableBusy"
              :tbody-tr-class="setRowClass"
              @sort-changed="sortingChanged"
              head-variant="light"
              responsive
              bordered
              hover
              show-empty
              no-local-sorting
            >
              <template #table-caption>
                <i class="fa fa-info-circle"></i>
                All patients and their most recent appointments
              </template>

              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(visits_per_month)="data">
                {{ data.value ? data.value : 1 }}
              </template>

              <template #cell(appointment_status)="data">
                <b-badge :variant="statusClass(data.item.appointment_status)">
                  {{ data.item.appointment_status }}
                </b-badge>
              </template>

              <template #cell(last_appointment_status)="data">
                <b-badge
                  :variant="
                    appointmentStatusFormatter(
                      data.item.last_appointment_status,
                      data.item.last_appointment_check,
                      data.item.last_appointment_summary_status
                    ).variant
                  "
                >
                  {{
                    appointmentStatusFormatter(
                      data.item.last_appointment_status,
                      data.item.last_appointment_check,
                      data.item.last_appointment_summary_status
                    ).text
                  }}
                </b-badge>
              </template>

              <template #cell(action)="data">
                <b-button-group>
                  <b-btn
                    size="sm"
                    variant="dark"
                    v-if="data.item.last_appointment_id"
                    @click="previewAppointment(data.item.last_appointment_id)"
                    title="Quick View"
                    v-b-tooltip.hover
                  >
                    Preview
                  </b-btn>
                  <b-btn
                    size="sm"
                    variant="info"
                    title="Open"
                    :to="{
                      name: 'Visit Tracker: General',
                      params: { patientId: data.item.id },
                    }"
                    v-b-tooltip.hover
                  >
                    View
                  </b-btn>
                </b-button-group>
              </template>
            </b-table>

            <div class="d-md-flex justify-content-between">
              <span>Total: {{ patientList ? patientList.total : 0 }}</span>

              <b-pagination-nav
                v-if="patientList && patientList.data"
                :link-gen="linkGen"
                :number-of-pages="patientList.last_page"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
                limit="30"
                align="right"
                last-number
                use-router
              ></b-pagination-nav>
            </div>
          </div>

          <b-modal id="preview-appointment" size="xl" scrollable centered>
            <template #modal-title>
              <small>
                Appointment for
                {{
                  selectedAppointment
                    ? `${selectedAppointment.patient.firstname} ${
                        selectedAppointment.patient.lastname
                      } on ${formatFancyDate(
                        selectedAppointment.appointment_date
                      )}`
                    : ""
                }}
              </small>
            </template>

            <div v-if="selectedAppointment">
              <preview-appointment
                id="appointmentPreview"
                :appointment="selectedAppointment"
              ></preview-appointment>
            </div>

            <template #modal-footer="{ cancel }">
              <!-- <b-button size="sm" variant="primary" @click="printAppointmentPreview()">
                Print
              </b-button> -->
              <b-button size="sm" variant="dark" @click="cancel()">
                Close
              </b-button>
            </template>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { geroendpoints } from "@/utils/endpoints";
import { gerohttp } from "@/utils/gerocarehttp";
import pagination from "@/mixins/pagination";
import $ from "jquery";
import "@/assets/js/printThis";
import { mapGetters } from "vuex";

export default {
  name: "VisitTracker",
  mixins: [pagination],
  components: {
    "preview-appointment": () =>
      import("@/components/partials/PreviewAppointment"),
  },
  data() {
    return {
      patientFields: [
        {
          key: "index",
          label: "S/N",
          sortable: false,
          show: true,
        },
        {
          key: "patient_name",
          label: "Patient",
          sortable: true,
          show: true,
        },
        {
          key: "patient_email",
          label: "Patient Email",
          sortable: false,
          show: false,
        },
        {
          key: "patient_phone",
          label: "Patient Phone",
          sortable: false,
          show: false,
        },
        {
          key: "sponsor_name",
          label: "Sponsor",
          sortable: true,
          show: true,
        },
        {
          key: "sponsor_email",
          label: "Sponsor Email",
          sortable: false,
          show: false,
        },
        {
          key: "sponsor_phone",
          label: "Sponsor Phone",
          sortable: false,
          show: false,
        },
        {
          key: "doctor_name",
          label: "Doctor",
          sortable: true,
          show: true,
        },
        {
          key: "doctor_email",
          label: "Doctor Email",
          sortable: false,
          show: false,
        },
        {
          key: "doctor_phone",
          label: "Doctor Phone",
          sortable: false,
          show: false,
        },
        {
          key: "visit_count",
          label: "Visits Left",
          sortable: true,
          show: true,
        },
        {
          key: "visits_per_month",
          label: "Visits Per Month",
          sortable: true,
          show: true,
        },
        {
          key: "appointment_status",
          label: "Appointment Status",
          sortable: false,
          show: true,
        },
        {
          key: "last_appointment_date",
          label: "Last Appt. Date",
          sortable: true,
          show: true,
        },
        {
          key: "last_appointment_expected_next_date",
          label: "Next Expected Date",
          sortable: true,
          show: true,
        },
        {
          key: "last_appointment_status",
          label: "Last Appt. Status",
          sortable: false,
          show: true,
        },
        {
          key: "registered_at",
          label: "Registration Date",
          sortable: true,
          show: true,
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
          show: true,
        },
      ],
      patientList: null,
      patientsTableBusy: false,
      query: "",
      filterOptions: [
        {
          value: "patient_name",
          text: "Patient name",
        },
        {
          value: "doctor_name",
          text: "Doctor name",
        },
        {
          value: "sponsor_name",
          text: "Sponsor name",
        },
        {
          value: "appointment_date",
          text: "Appointment Date",
        },
        {
          value: "registration_date",
          text: "Registration Date",
        },
        {
          value: "next_expected_date",
          text: "Next Expected Date",
        },
        {
          value: "missed_visits",
          text: "Missed Next Expected Visits",
        },
      ],
      filterOptionSelected: "patient_name",
      filterText: "",
      selectedAppointment: null,
      exporting: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    patients() {
      return this.patientList?.data || [];
    },
  },
  methods: {
    fetchAll() {
      this.patientsTableBusy = true;

      this.query = this.checkQueryParams(this.$route.query)
        ? Object.entries(this.$route.query)
            .map(
              ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&")
        : this.query;
     
      gerohttp
        .get(geroendpoints.FETCH_VISIT_TRACKER + `?${this.query}`)
        .then((response) => {
          this.patientList = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.patientsTableBusy = false;
          this.$router.replace({ "filter[missed_visits_from]": null });
        });
    },

    checkQueryParams(queryParams) {
      let count = 0;
      for (let prop in queryParams) {
        if (
          prop == "filter[missed_visits_from]" ||
          prop == "filter[missed_visits_to]"
        ) {
          count++;
        }
      }
      return count > 0;
    },

    statusClass(status) {
      let variant = "";

      switch (status) {
        case "booked":
          variant = "success";
          break;

        case "not booked":
          variant = "warning";
          break;

        case "expired":
          variant = "danger";
          break;

        default:
          break;
      }

      return variant;
    },
    appointmentStatusFormatter(status, check, summaryStatus) {
      if (status == 0) {
        return {
          text: "completed",
          variant: "success",
        };
      }

      if (status == 1) {
        if (check == 0) {
          return {
            text: "not checked in",
            variant: "dark",
          };
        }

        if (check == 1) {
          return {
            text: "checked in",
            variant: "dark",
          };
        }

        if (check == 2 && summaryStatus) {
          return {
            text: "report submitted",
            variant: "info",
          };
        }

        if (check == 2) {
          return {
            text: "checked out",
            variant: "dark",
          };
        }
      }

      if (status == 2) {
        return {
          text: "doctor rescheduled",
          variant: "primary",
        };
      }

      if (status == 3) {
        return {
          text: "doctor rescheduled",
          variant: "primary",
        };
      }

      return {
        text: "",
        variant: "",
      };
    },
    filterTracker() {
      if (
        [
          "appointment_date",
          "registration_date",
          "next_expected_date",
          "missed_visits",
        ].includes(this.filterOptionSelected)
      ) {
        if (this.filterText.length < 24) {
          this.$toast.error("Select a valid date range");
          return;
        }

        let dates = this.filterText.split("to").map((date) => date.trim());

        if (this.filterOptionSelected == "appointment_date") {
          this.query = `filter[appointment_from_date]=${dates[0]}&&filter[appointment_to_date]=${dates[1]}`;
        } else if (this.filterOptionSelected == "registration_date") {
          this.query = `filter[registration_from_date]=${dates[0]}&&filter[registration_to_date]=${dates[1]}`;
        } else if (this.filterOptionSelected == "next_expected_date") {
          this.query = `filter[next_expected_from_date]=${dates[0]}&&filter[next_expected_to_date]=${dates[1]}`;
        } else if (this.filterOptionSelected == "missed_visits") {
          this.query = `filter[missed_visits_from]=${dates[0]}&&filter[missed_visits_to]=${dates[1]}`;
        }

        this.fetchAll();

        return;
      }

      this.query = `filter[${this.filterOptionSelected}]=${this.filterText}`;
      this.fetchAll();
    },
    resetTracker() {
      this.query = "";
      this.filterText = "";
      this.fetchAll();
    },
    previewAppointment(appointmentId) {
      gerohttp
        .get(geroendpoints.FETCH_APPOINTMENT.replace(":id", appointmentId), {
          params: {
            include: "patient_doctor_user",
          },
        })
        .then((response) => {
          this.selectedAppointment = response;
          this.$bvModal.show("preview-appointment");
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    printAppointmentPreview() {
      $("#appointmentPreview").printThis({
        debug: false,
        importCSS: true,
        importStyle: true,
        printContainer: true,
        loadCSS: "",
        pageTitle: `Appointment for
          ${this.selectedAppointment.patient.firstname}
          ${this.selectedAppointment.patient.lastname}
          on ${this.formatFancyDate(
            this.selectedAppointment.appointment_date
          )}`,
        removeInline: false,
        removeInlineSelector: "*",
        printDelay: 300,
        header: null,
        footer: null,
        base: false,
        formValues: true,
        canvas: false,
        doctypeString: "...",
        removeScripts: false,
        copyTagClasses: false,
        beforePrintEvent: null,
        beforePrint: null,
        afterPrint: null,
      });
    },
    sortingChanged(context) {
      let { sortBy, sortDesc } = context;

      let sortValue = sortBy;

      switch (sortValue) {
        case "last_appointment_expected_next_date":
          sortValue = "next_expected_date";
          break;

        case "registered_at":
          sortValue = "registration_date";
          break;

        case "sponsor":
          sortValue = "sponsor_name";
          break;

        case "doctor":
          sortValue = "doctor_name";
          break;

        default:
          break;
      }

      sortValue = (sortDesc ? "-" : "") + sortValue;

      let queryParams = new URLSearchParams(this.query);
      if (queryParams.has("sort")) {
        this.query = this.query.replace(queryParams.get("sort"), sortValue);
      } else {
        this.query += "&sort=" + sortValue;
      }

      this.fetchAll();
    },
    exportVisits() {
      this.exporting = true;

      gerohttp
        .get(
          geroendpoints.FETCH_VISIT_TRACKER +
            `?${this.query}&export=1&email=${this.user.email}`
        )
        .then(() => {
          this.$toast.success(
            "Export initiated successfully. You should receive it in your mail shortly"
          );
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.exporting = false;
        });
    },
    checkAppointmentStatus(apppointmentDate, appointmentCheck) {
      const appointmentDueDate = new Date(apppointmentDate).getTime();
      const currentTimestamp = Date.now();
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      let dateHasPassed = false;

      if (
        appointmentDueDate + twentyFourHours <= currentTimestamp
        && appointmentCheck != 3
      ) {
        dateHasPassed = true;
      }

      return dateHasPassed;
    },
    setRowClass(item) {
      if (
        this.checkAppointmentStatus(
          item.last_appointment_date,
          item.last_appointment_check
        )
      ) {
        return "table-danger";
      }
    },
  },

  mounted() {
    this.fetchVisitTrack();
  },
};
</script>
